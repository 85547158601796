(function () {
  'use strict';

  angular
    .module('app')
    .controller('HomeController', HomeController);

  /** @ngInject */
  function HomeController(companiesService) {
    var vm = this;

    vm.$onInit = function () {
      vm.name = '';
      vm.category = '';
      companiesService.getCompanies().then(function (resolve) {
        vm.companies = resolve.data;
        vm.categories = getCategories();
      });
    };

    function getCategories() {
      var categoryArray = [];
      angular.forEach(vm.companies, function (company) {
        if (company.category && categoryArray.indexOf(company.category) === -1) {
          categoryArray.push(company.category);
        }
      });
      return categoryArray;
    }
  }
})();
