(function () {
  'use strict';

  angular
    .module('app')
    .factory('_', lodashFactory);

  /** @ngInject */
  function lodashFactory($window, $log) {
    if (!$window._) {
      // If lodash is not available you can now provide a
      // mock service, try to load it from somewhere else,
      // redirect the user to a dedicated error page, ...
      $log.error('Lodash not found in your project');
    }
    return $window._;
  }
})();
