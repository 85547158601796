(function () {
  'use strict';

  angular
    .module('app')
    .controller('RestaurantsController', RestaurantsController);

  /** @ngInject */
  function RestaurantsController(companiesService) {
    var vm = this;

    vm.$onInit = function () {
      companiesService.getCompanies().then(function (resolve) {
        vm.companies = resolve.data;
      });
    };
  }
})();
