(function () {
  'use strict';

  angular
    .module('app')
    .service('companiesService', companiesService);

  /** @ngInject */
  function companiesService($http) {
    var url = 'data/companies.json';
    var service = {
      getCompanies: getCompanies
    };
    return service;

    /**
     * @description method to call ApiService to get Companies
     * @return {object} - return a promise object with the api response
     */
    function getCompanies() {
      return $http.get(url);
    }
  }
})();
