(function () {
  'use strict';

  var home = {
    templateUrl: 'app/home/home.html',
    bindings: {
    },
    controller: 'HomeController'
  };

  angular
    .module('app')
    .component('home', home);
})();
