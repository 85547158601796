angular
  .module('app')
  .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
    .state('home', {
      url: '/',
      component: 'home'
    })
    .state('localizador', {
      url: '/localizador',
      component: 'localizador'
    })
    .state('parkings', {
      url: '/parkings',
      component: 'parkings'
    })
    .state('restaurants', {
      url: '/restaurants',
      component: 'restaurants'
    });
    // .state('company', {
    //   url: '/company/:slug',
    //   component: 'company'
    // })
    // .state('contact', {
    //   url: '/contact',
    //   component: 'contact'
    // })
    // .state('test', {
    //   url: '/test',
    //   component: 'test'
    // });
}
