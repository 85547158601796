(function () {
  'use strict';

  var restaurants = {
    templateUrl: 'app/restaurants/restaurants.html',
    bindings: {
    },
    controller: 'RestaurantsController'
  };

  angular
    .module('app')
    .component('restaurants', restaurants);
})();
