(function () {
  'use strict';

  angular
    .module('app')
    .controller('ParkingsController', ParkingsController);

  /** @ngInject */
  function ParkingsController() {
    var vm = this;

    vm.$onInit = function () {
    };
  }
})();
