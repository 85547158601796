(function () {
  'use strict';

  var parkings = {
    templateUrl: 'app/parkings/parkings.html',
    bindings: {
    },
    controller: 'ParkingsController'
  };

  angular
    .module('app')
    .component('parkings', parkings);
})();
