(function () {
  'use strict';

  var localizador = {
    templateUrl: 'app/localizador/localizador.html',
    bindings: {
    },
    controller: 'LocalizadorController'
  };

  angular
    .module('app')
    .component('localizador', localizador);
})();
